import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import s from './styles.module.css';

class ContactPage extends React.PureComponent<any, any> {
  render() {
    return (
      <div>
        <h2 className="my-4 text-center text-4xl font-semibold text-gray-600 lg:my-4">
          Contact Us
        </h2>
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className={`${s.contactContainer} center`}>
              {this.props.user && (
                <div className="box p-20">
                  <div className="">
                    <h3 className="sub-title m-t-0 m-b-20">Phone/Email</h3>
                    <div className="text-center">
                      Phone:{' '}
                      <a
                        href={
                          this.props.user.countryId === 1
                            ? 'tel:80026948'
                            : this.props.user.countryId === 2
                            ? 'tel:1800071'
                            : 'tel:966920033368'
                        }
                      >
                        {this.props.user.countryId === 1
                          ? '800 - BOXIT(80026948)'
                          : this.props.user.countryId === 2
                          ? '1800071'
                          : '+966 92 003 3368'}
                      </a>
                      <br />
                      Email:{' '}
                      <a href="mailto:care@boxitstorage.com">
                        care@boxitstorage.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {!this.props.user && (
                <div className="box p-20">
                  <div className="">
                    <h3 className="sub-title m-t-0 m-b-20">UAE</h3>
                    <h3 className="sub-title m-t-0 m-b-20">Phone/Email</h3>
                    <div className="text-center">
                      Phone: <a href="tel:80026948">800 - BOXIT(80026948)</a>
                      <br />
                      Email:{' '}
                      <a href="mailto:care@boxitstorage.com">
                        care@boxitstorage.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {!this.props.user && (
                <div className="box p-20">
                  <div className="">
                    <h3 className="sub-title m-t-0 m-b-20">Kuwait</h3>
                    <h3 className="sub-title m-t-0 m-b-20">Phone/Email</h3>
                    <div className="text-center">
                      Phone: <a href="tel:1800071">1800071</a>
                      <br />
                      Email:{' '}
                      <a href="mailto:care@boxitstorage.com">
                        care@boxitstorage.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              )}
              {!this.props.user && (
                <div className="box p-20">
                  <div className="">
                    <h3 className="sub-title m-t-0 m-b-20">KSA</h3>
                    <h3 className="sub-title m-t-0 m-b-20">Phone/Email</h3>
                    <div className="text-center">
                      Phone: <a href="tel:80026948">+966 92 003 3368</a>

                      <br />
                      Email:{' '}
                      <a href="mailto:care@boxitstorage.com">
                        care@boxitstorage.com
                      </a>
                      <br />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    user: (state as any).user.user,
  }),
  dispatch => bindActionCreators({}, dispatch)
)(ContactPage);
